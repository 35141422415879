const API_URL = process.env.REACT_APP_API_URL_AGANWADI_SERVICE + "/anganwadi/api";


// DISTRICT URL
export const DISTRICT_URL = API_URL + "/rajasthan-schema/get-all-schema";

// DIVISION URL

export const DIVISION_URL = API_URL + "/rajasthan-schema/get-all-division";

// RAJASTAN SCHEMA BY ID

export const RAJASTAN_SCHEMA_BY_ID  = API_URL+ "/rajasthan-schema/get-rajasthan-schema-by-schema-id"

// FINICIAL PROGRESSS

export const FINICIAL_PROGRESSS  = API_URL+"/rajasthan-schema/get-all-financial-progress"

// USER DATA

export const USER_DATA  = API_URL+"/rajasthan-schema/get-all-users-data"

// Physical progress

export const PHYSICAL_PROGRESSS  = API_URL+"/rajasthan-schema/get-all-physical-progress"

// Village Name 

export const VILLAGE_NAME  = API_URL+"/rajasthan-schema/get-village-name"

// User List
export const USER_LIST  = API_URL+"/rajasthan-schema/users-list"

// Firm Name

export const FIRM_NAME  = API_URL+"/rajasthan-schema/get-village-name"

// Pichart Api

export const PIECHAT_API_URL  = API_URL+"/rajasthan-schema/get-piechart"


// user name 

export const USER_NAME_URL  = API_URL+"/rajasthan-schema/get-names"

// view pdf

export const VIEW_PDF=API_URL+'/generate-pdf';

// HINDERANCE_REPORT
export const HINDERANCE_REPORT=API_URL+'/rajasthan-schema/get-hiderance-report';

// export const 
export const REVISITE_REPORT=API_URL+'/rajasthan-schema/action-taken-report';

// export letter pdf genrate

export const LETTER_PDF_GENRATE=API_URL+'/rajasthan-schema/letter-report'

// bill report pdf genrate

export const BILL_PDF_GENRATE=API_URL+'/rajasthan-schema/bill-report';

// Avability Of Contractor Names

export const AVABILITY_OF_CONTRACTOR=API_URL+'/rajasthan-schema/get-is-contractor-available';

// physical Progress Report

export const PHYSICAL_PROGRESS_REPORT_PDF=API_URL+'/rajasthan-schema/get-physical-progress'

// Contractor List Pdf Report

export const AVABILITY_OF_CONTRACTOR_PDF=API_URL +'/rajasthan-schema/get-is-contractor-available-pdf';
export const Non_AVABILITY_OF_CONTRACTOR_PDF=API_URL+"/rajasthan-schema/get-no-contractor-available"
// Letter upload  pdf

export const UPLOAD_FILE_PDF=API_URL+"/rajasthan-schema/web-upload-pdf-latters-bills"

// WEB LETTER,BILL AND FACTORY INSPECTION RREPORT DOWNLOAD
export const VIEW_WEB_LEETER_PDF=API_URL+"/rajasthan-schema/web-letter-report"
export const VIEW_WEB_BILL_PDF=API_URL+"/rajasthan-schema/web-bill-report"
export const VIEW_WEB_FACTORY_INSPECTION_PDF=API_URL+"/rajasthan-schema/factory-report"
