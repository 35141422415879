import React, { useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Download } from 'lucide-react'
import { Checkbox } from 'src/components/ui/checkbox'
import { Label } from 'src/components/ui/label';
import VillageMain from 'src/components/ui/Common/Filter/Village/VillageMain';
import DivisionMain_5 from 'src/components/ui/Common/Filter/Division_1/DivisionMain_5'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { dropDownReducerState, updateCalender } from 'src/redux/Slicer/dropdownSlicer';
import CalenderComp from 'src/components/ui/Common/CalenderComp';
import DateFilter from 'src/components/ui/Common/DateFilter'
import { reportDownload } from 'src/service/services';
import { VIEW_PDF } from 'src/service/apis';
import UserMain from '../../../components/ui/Common/Filter/Users/UserMain';
import { stringifyObjectToRequestQuery } from '../../../helpers';

const ProgressReport = () => {
    const [check, setCheck] = useState('daily');
    const { monthYear,  calendeChange, downloadReportDivision,  villageState,userNameListState } = useSelector((state: RootState) => dropDownReducerState(state))

    const dispatch = useDispatch()
    const dateChange = (val: string) => {
        dispatch(updateCalender(val))
    }

    const handleProgressReport = () => {
        if (check === 'daily' && downloadReportDivision !== '' && downloadReportDivision !== undefined) {
            let query = `dt=${calendeChange}&division=${downloadReportDivision}`
            let data = reportDownload(VIEW_PDF, query)

            // window.open(`${VIEW_PDF}?dt=${calendeChange}&division=${downloadReportDivision}`, 'rel=noopener noreferrer')
        } else if (check === 'monthly' && downloadReportDivision !== '' && downloadReportDivision !== undefined) {
            // window.open(`${VIEW_PDF}?bym=${monthYear}&division=${downloadReportDivision}${villageState === undefined ? '' : `&villageName=${villageState}`}`, 'rel=noopener noreferrer')
           let querData={
            bym:monthYear,
            division:downloadReportDivision,
            villageName:villageState,
            nameOfQC:userNameListState
           }
            const queryString = stringifyObjectToRequestQuery(querData)
            // let query = `bym=${monthYear}&division=${downloadReportDivision}&villageName=${villageState}&nameOfQC=${userNameListState}`
            console.log(queryString)
            
            if(queryString){
                let data = reportDownload(VIEW_PDF, queryString)
            }
            // let data = reportDownload(VIEW_PDF, query)
        }

    }

  
    return (
        <div className='w-full'>

            <h1 className='text-left text-blue text-sm font-bold p-4'>Download Progress Report</h1>


            <div className='w-full flex space-x-20 justify-center items-center -ml-2 md:ml-2 mt-1'>
                <div className='flex  items-center '>
                    <Checkbox
                        id='checkbox'
                        value={check}
                        checked={check === 'monthly'}
                        onCheckedChange={() => setCheck('monthly')}
                        className='rounded-full' />
                    <Label htmlFor='checkbox' className='text-black text-sm font-normal ml-2'>Monthly</Label>
                </div>
                <div className='flex items-center'>
                    <Checkbox
                        id='checkboxs'
                        value={check}
                        checked={check === 'daily'}
                        onCheckedChange={() => setCheck('daily')}
                        className='rounded-full' />
                    <Label htmlFor='checkboxs' className='text-black text-sm font-normal ml-2'>Daily</Label>
                </div>



            </div>
            <div className='w-full flex items-center justify-center'>
                <div className=' w-full px-4 md:px-0 md:w-1/2 space-y-2 '>

                    {
                        check === 'daily' ? (<><CalenderComp setDatevalue={dateChange} />
                            <DivisionMain_5 />
                        </>) : (<>
                            <DateFilter monthYear={monthYear} />
                            <DivisionMain_5 />
                            <UserMain/>
                            <VillageMain />
                        </>)
                    }
                    {/* {
                        check === 'daily' ? (<CalenderComp setDatevalue={dateChange} />) : (<DateFilter monthYear={monthYear} />)
                    }

                    <DivisionMain_5 />

                    {
                        check === 'daily' ? (<></>) : (<VillageMain />)
                    } */}
                </div>
            </div>
            <div className='w-full flex justify-center my-3'>
                <Button className='bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
                    onClick={handleProgressReport}
                    disabled={downloadReportDivision === undefined }>
                    <Download /> <span>Download Progress Report</span>
                </Button>
            </div>
        </div>

    )
}

export default ProgressReport
