import axios, { AxiosResponse } from "axios";
import { PIECHAT_API_URL, UPLOAD_FILE_PDF } from "./apis";
import { toast } from "react-toastify";

export function getPichart (query:string): Promise<AxiosResponse<any>> {
    return axios.get(`${PIECHAT_API_URL}?${query}`).then((d: AxiosResponse) => 
  
    d.data
    ) 
  }


  // download pdf function
  export function reportDownload(baseUrl: string, query: string): Promise<AxiosResponse<any>> {
    return axios.get(`${baseUrl}?${query}`).then((d: AxiosResponse) => {
        if (d.data?.code === 404) {
            toast.error(d.data?.msg);
            return Promise.resolve({} as AxiosResponse<any>);
        } else {
            window.open(`${baseUrl}?${query}`,'rel=noopener noreferrer');
            return Promise.resolve({} as AxiosResponse<any>);
        }
    });
}

export function reportDownloadWithoutDivision(baseUrl: string,): Promise<AxiosResponse<any>> {
    return axios.get(`${baseUrl}`).then((d: AxiosResponse) => {
        if (d.data?.code === 404) {
            toast.error(d.data?.msg);
            return Promise.resolve({} as AxiosResponse<any>);
        } else {
            window.open(`${baseUrl}`,'rel=noopener noreferrer');
            return Promise.resolve({} as AxiosResponse<any>);
        }
    });
}

export const postUploadFileApi = (postData: any) => {
    return axios
      .post(`${UPLOAD_FILE_PDF}`, postData)
      .then((d: AxiosResponse<any>) => d?.data);
  };




  